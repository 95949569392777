import { useFormContext, useWatch } from "react-hook-form";
import { Error, RawIcon, SmallTextMain, TextMain, flexStyles } from "ui";
import { ConferenceType } from "schemas";
import { Button, RadioCard } from "../ui";
import { useUrlWithBack } from "../../hooks";
import { useZoomDetails } from "../../store";

export function Conferencing() {
  const hasZoom = useZoomDetails();
  const { setValue, setError, clearErrors } = useFormContext();
  const conferenceType = useWatch({ name: "conferenceType" });
  const zoomUrl = useUrlWithBack("/zoom/setup");

  const onClick = (newType: ConferenceType) => async () => {
    const zoomCheck = newType === "Zoom" && !hasZoom;
    if (zoomCheck) {
      setError("conferenceType", { message: "Please connect your Zoom account" });
    } else {
      clearErrors("conferenceType");
    }
    setValue("conferenceType", newType, { shouldValidate: !zoomCheck });
  };
  return (
    <div className={flexStyles.vert050}>
      <RadioCard
        checked={conferenceType === "GoogleMeet"}
        onClick={onClick("GoogleMeet")}
        left={<RawIcon iconType="GoogleMeet" />}
        title={<TextMain>Google Meet</TextMain>}
      />
      <RadioCard
        checked={conferenceType === "Zoom"}
        onClick={onClick("Zoom")}
        left={<RawIcon iconType="Zoom" />}
        title={<TextMain>Zoom</TextMain>}
      >
        {!hasZoom && conferenceType === "Zoom" && (
          <div className={flexStyles.vert125}>
            <SmallTextMain>Connect your Zoom account to schedule and conduct meetings seamlessly.</SmallTextMain>
            <Button to={zoomUrl}>Setup Zoom</Button>
          </div>
        )}
      </RadioCard>
      <Error name="conferenceType" />
    </div>
  );
}
