import { useEffect } from "react";
import { useLiteSiteStore } from "..";
import { apiRequest } from "../apiRequest";

export function useZoomDetails() {
  useEffect(() => {
    getZoomDetails();
  }, []);

  return useLiteSiteStore(({ hasZoom }) => hasZoom);
}

async function getZoomDetails() {
  let result: boolean;
  try {
    await apiRequest({ url: "zoom/details" });
    result = true;
  } catch {
    result = false;
  }

  useLiteSiteStore.setState({ hasZoom: result });
}
